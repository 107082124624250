<template>
  <div class="main-content">
    <b-card>
      <good-data-table
        ref="deposits_categories"
        :button-label="$t('Add Category')"
        @add-new-info="New_Category"
        :columns="columns"
        :no-serial="true"
        :total-column="'response.responseData.total'"
        :data-column="'response.responseData.data'"
        :api-endpoint="'/account/deposits-category'"
      />
    </b-card>
    <validation-observer ref="Create_Category">
      <b-modal v-model="categoryModal" hide-footer size="md" id="New_Category" :title="editmode ? $t('Edit') : $t('Add')">
        <b-form @submit.prevent="Submit_Category">
          <b-row>
            <!-- Name Category -->
            <b-col md="12">
              <validation-provider name="Name Category" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group :label="$t('Title') + ' ' + '*'">
                  <b-form-input :state="getValidationState(validationContext)" aria-describedby="category-feedback" label="name" v-model="category.title"></b-form-input>
                  <b-form-invalid-feedback id="category-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <b-form-group :label="$t('Description')">
                <textarea v-model="category.description" rows="4" name="Description" id="Description" class="form-control" :placeholder="$t('Write a description')"></textarea>
              </b-form-group>
            </b-col>

            <b-col md="12" class="mt-3">
              <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{ $t('Submit') }}</b-button>
              <div v-once class="typo__p" v-if="SubmitProcessing">
                <div class="spinner sm spinner-primary mt-3"></div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { showDangerNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { mapGetters } from 'vuex'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/account`
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
export default {
  components: {
    GoodDataTable,
  },
  data() {
    return {
      isLoading: true,
      categoryModal: false,
      SubmitProcessing: false,
      editmode: false,
      limit: '10',
      category: {
        id: '',
        title: '',
        description: '',
      },
    }
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t('Title'),
          field: 'title',
        },
        {
          label: this.$t('Description'),
          field: 'description',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Category(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Delete_Category(props)
                  }
                })
              },
            },
          ],
        },
      ]
    },
  },

  methods: {
    //------------- Submit Validation Create & Edit Category
    Submit_Category() {
      this.$refs.Create_Category.validate().then(success => {
        if (!success) {
          showDangerNotification(this, 'Please fill all data correctly')
        } else {
          if (!this.editmode) {
            this.Create_Category()
          } else {
            this.Update_Category()
          }
        }
      })
    },

    //------ Event Validation State
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    //--------------------------Show Modal (new Category) ----------------\\
    New_Category() {
      this.reset_Form()
      this.editmode = false
      this.categoryModal = true
    },

    //-------------------------- Show Modal (Edit Category) ----------------\\
    Edit_Category(cat) {
      this.reset_Form()
      this.category = cat
      this.editmode = true
      this.categoryModal = true
    },

    //--------------------------- reset Form ----------------\\
    reset_Form() {
      this.category = {
        id: '',
        title: '',
        description: '',
      }
    },

    //----------------------------------Create new Category ----------------\\
    Create_Category() {
      this.SubmitProcessing = true
      axios
        .post(apiUrl + '/deposits-category', {
          title: this.category.title,
          description: this.category.description,
        })
        .then(response => {
          this.$refs.deposits_categories.loadItems()
          showSuccessNotification(this, 'Deposite Category created')
          this.SubmitProcessing = false
          this.categoryModal = false
        })
        .catch(error => {
          showDangerNotification(this, 'Failed to create deposit category')
          this.SubmitProcessing = false
        })
    },

    //---------------------------------- Update Category ----------------\\
    Update_Category() {
      this.SubmitProcessing = true
      axios
        .put(apiUrl + '/deposits-category/' + this.category.id, {
          title: this.category.title,
          description: this.category.description,
        })
        .then(response => {
          this.$refs.deposits_categories.loadItems()
          showSuccessNotification(this, 'Deposit Category updated')
          this.SubmitProcessing = false
          this.categoryModal = false
        })
        .catch(error => {
          showDangerNotification(this, 'Failed to update category')
          this.SubmitProcessing = false
        })
    },

    //--------------------------- Delete Category----------------\\
    Delete_Category(props) {
      axios
        .delete(apiUrl + '/deposits-category/' + props.id)
        .then(() => {
          this.$refs.deposits_categories.loadItems()
          showSuccessNotification(this, 'Category Deleted successfully')
        })
        .catch(() => {
          showDangerNotification(this, 'Failed to delete category')
        })
    },
  }, //end Methods

  //----------------------------- Created function-------------------

  mounted: function () {
    this.$refs.deposits_categories.loadItems()
  },
}
</script>
